<template>
  <!--begin::Main-->
  <div class="d-flex flex-column flex-root">
    <!--begin::Login-->
    <div class="login login-3 login-signin-on d-flex flex-row-fluid" id="kt_login">
      <div class="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid" :style="{ backgroundImage: `url(${backgroundImage})` }">
        <div class="login-form text-center text-white p-7 position-relative overflow-hidden">
          <!--begin::Login Header-->
          <div class="d-flex flex-center mb-10">
            <a href="#">
              <img src="media/logos/kyb.jpg" class="max-h-100px" alt="" />
            </a>
          </div>
          <!--end::Login Header-->
          <router-view></router-view>
        </div>
      </div>
    </div>
    <!--end::Login-->
  </div>
  <!--end::Main-->
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "auth",
  methods: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "media/bg/bg-1.jpg";
    }
  }
};
</script>
